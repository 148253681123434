html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  font-family: 'Roboto', sans-serif;
  background-color: #f0f0f0;
}

body {
  overflow-y: scroll;
}

#__next {
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
